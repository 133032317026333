<template>
	<div id="app">
		<keep-alive>
			<router-view :key="$route.path" />
		</keep-alive>
	</div>
</template>

<script>
import '@/css/main.scss'

export default {
	name: 'App',
	beforeMount() {
		if (!this.domainConfig) {
			this.$store.commit('setDomain', require('@/utils/config/domainConfig.json')[this.host])
      console.log(this.host,23);
      console.log(require('@/utils/config/domainConfig.json')['quranonline.one'],22);
      this.$store.state.domainConfig = require('@/utils/config/domainConfig.json')['quranonline.one']
      console.log(this.$store.state.domainConfig);
		}

		const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		if (flag) {
			this.$store.state.deviceType = 'mobile'
		} else {
			this.$store.state.deviceType = 'desktop'
		}
		console.log(this.$store.state.deviceType)
	},
	created() {
		let debug = this.$global.GetQueryString('db')
		if (debug) {
			this.$store.commit('setDebug', true)
		}
	},
}
</script>
